import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import Maintenance from "../components/Maintenance"

const MaintenancePage = (props) => {

    return (
        <>
            <SEO title="Under Maintenance" />

            <Maintenance />

        </>
    )
}
export default MaintenancePage;