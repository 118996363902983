import React, { useEffect } from "react";
import { Link } from "gatsby";
import { Space, Row, Col } from "antd";
import Logo from "../images/logo/ayana-rewards@2x.png";

export default function Maintenance({ }) {
  return (
    <>
      <div className="home__banner">
        <div className="logo-rewards">
          <img src={Logo} width="180" />
        </div>
      </div>
      <div className="home__benefit text--center">
        <div className="container container--500">
          <h1>Under Maintenance</h1>
          <p>
            We are upgrading our system for your better experience. The pages
            you are looking for are temporarily unavailable, we apologize for
            the inconvenience. For inquiries, click{" "}
            <Link to="https://www.ayana.com/contact#ayana-rewards-inquiry">
              here.
            </Link>
          </p>
        </div>
        <div className="container">
          <div className="rd__desc style_under_maintenance_card">
            You can also find us on email at{" "}
            <a href="mailto:info@ayanarewards.com">info@ayanarewards.com</a> or
            call our representatives below
          </div>
          <Row className="block-item-wrapper" justify="center" gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 8 }} className="block-item">
              <div className="rd__desc style_under_maintenance_card">
                <b>
                  AYANA RESORT AND SPA, BALI <br />
                  THE VILLAS AT AYANA RESORT, <br />
                  BALI <br />
                </b>
                <span>(+62) 361 702 222</span>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }} className="block-item">
              <div className="rd__desc style_under_maintenance_card">
                <b>
                  RIMBA JIMBARAN BALI <br />
                  BY AYANA
                  <br />
                </b>
                <span>(+62) 361 846 8468</span>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }} className="block-item">
              <div className="rd__desc style_under_maintenance_card">
                <b>
                  AYANA KOMODO RESORT <br />
                  AYANA LAKO DI’A <br />
                </b>
                <span>(+62) 385 2441000</span>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }} className="block-item">
              <div className="rd__desc style_under_maintenance_card">
                <b>
                  AYANA MIDPLAZA <br />
                  JAKARTA
                  <br />
                </b>
                <span>(+62) 21 2510 888</span>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }} className="block-item">
              <div className="rd__desc style_under_maintenance_card">
                <b>
                  DELONIX HOTEL <br />
                  KARAWANG <br />
                </b>
                <span>(+62) 267 644 370</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
